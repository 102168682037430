import React from "react";
import { Script } from "gatsby";
import { AnimatePresence } from "framer-motion";
import "./src/styles/global.css";

/*
 AnimatePresence without this would not allow exit to be caught.
 This is a limitation of Gatsby, as it unmount the entire tree every time,
 and AnimatePresence is used by framer motion to handle exiting and entering animation
 by recognizing when a component has been unmount
*/
export function wrapPageElement({ element, props }) {
  return <AnimatePresence mode="wait">{element}</AnimatePresence>;
}

// add GTM to root element created by Gatsby
export function wrapRootElement({ element }) {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}`}
        strategy="idle"
      />
      <Script id="gtm-init" strategy="idle">
        {`
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
        `}
      </Script>
      {element}
    </>
  );
}

// send page change event to GTM with dataLayer
export function onRouteUpdate({ _, pluginOptions }) {
  let dataLayer = window.dataLayer || [];
  dataLayer.push({ event: "gatsby_route_change" });
}
